.image-card-container{
	display: inline-block;
	width: 120px;
	height: 120px;
	padding: 5px;
	box-sizing: border-box;
	overflow: hidden;
}

.image-card-content{
	position: relative;
	width: 100%;
	height: 100%;
	background-color: #ddd;
	display: flex;
	justify-content: center;
	align-items: center;
}

.image-card-content label{
	margin: 0;
}

.image-card-content img{
	cursor: pointer;
}

.img-width{
	width: 100%;
	height: auto;
	/* max-height: 110px; */
}

.img-height{
	height: 100%;
	width: auto;
	/* max-width: 110px; */
}

.image-card-content input{
	display: none !important;
}

.image-card-actions{
	position: absolute;
	right: 7px;
	top: 5px;
}

.btn-action{
	padding: 5px 3px !important;
}

.btn-star-active{
	color: #fff !important;
	background: #FF9500 !important;
	border: 1px solid #FF9500 !important;
}

.btn-star-deactive{
	color: #fff !important;
	background: #bbb !important;
	border: 1px solid #bbb !important;
}

.btn-star-deactive:hover{
	color: #fff !important;
	background: #FF9500 !important;
	border: 1px solid #FF9500 !important;
}